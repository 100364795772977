import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="mx-auto prose lg:prose-xl dark:prose-invert dark:prose-invert prose-a:text-indigo-600 dark:prose-a:text-indigo-400">
      <h1>404</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
      <a href="/">Back to Home</a>
    </div>
  </Layout>
);

export default NotFoundPage;
